$breakpoints: (xs: 0, sm: 576px, md: 768px, lg: 992px, xl: 1200px);

$white-ef: #f2f1ef;
$white-af: #FAFAFF;
$light-gray-f6: #f9f7f6;
$light-gray-f7: #d2d2d2;
$gray-ee: #eee;
$gray-cc: #ccc;
$gray-99: #999;
$gray-d5: #d8d7d5;
$gray-62: #475362;
$gray-f0: #E7E9F0;
$pattens-blue-f8: #e8f6f8;
$dark-blue-bg-43: #223043;
$dark-blue-bg-42: #283042;
$dark-blue-32: #1F2532;
$dark-blue-3f: #1D2C3F;
$dark-blue-46: #262f43;
$dark-blue-4d: #22374D;
$dark-blue-4c: #2C3A4C;
$echo-blue-a5: #697CA5;
$echo-blue-d3: #AAB6D3;
$blue-77: #263877;
$blue-77aa: #263877aa;
$blue-ff: #007bff;
$blue-b3: #0056b3;
$bondi-blue-ae: #00A2ae;
$green-11: #B7CE11;
$green-18: #B5CE18;
$green-74FF00: #74FF00;
$green-99FF00: #99FF00;
$green-06: #8AE506;
$green-6CC711: #6CC711;
$green-20: #6EB520;
$green-21: #429321;
$green-a1: #cde1a1;
$dark-green-31: #132431;
$yellow-06: #F8C406;
$orange-16: #F59D16;
$orange-47: #F5AC47;
$orange-F7BF15: #F7BF15;
$orange-F59E15: #F59E15;
$orange-19: #F46519;
$pink-5b: #E5005B;
$pink-ed: #FCE3ED;

@import "constants";

.btn-spacing{
  margin-left: 5px;
  margin-bottom: 5px;
}

.btn.pink {
  background-color: $pink-5b;
  color: #EAE4E1;
}

.btn.green {
  background-color: $green-11;
  color: white;
}

.cta-button {
  width: 100%;
  max-width: 280px;
  text-align: center;
  min-height: 45px;
  border-radius: 36px;
  font-weight: 600;
  color: $dark-blue-3f;
  background: linear-gradient(to bottom, $green-99FF00, $green-21);
  cursor: pointer;
  line-height: 1.25;

  &:hover {
    color: white;
  }

  &.pseudo-button {
    transition: transform 1s;
    min-height: 44px;
    padding: 10px 20px;
    width: fit-content;
    margin: auto;
    text-decoration: none;
    display: block;
    max-width: unset;
    font-size: 18px;

    &.orange,
    &.secondary,
    &.ternary {
      margin: 0;
    }

    &:hover {
      transform: scale(1.1);
    }
  }

  &.orange {
    background-image: linear-gradient(to top, $orange-F7BF15 0%, $orange-F59E15 100%) !important;
    background-color: $orange-F59E15;
    color: black;
  }

  &.secondary {
    background: none;
    border: solid 1px $green-99FF00;
    color: white;
    font-weight: normal;

    &:hover {
      color: $green-99FF00;
    }
  }

  &.ternary {
    background: none;
    font-weight: 700;
    font-size: 20px;
    border: solid 2px #50a850;
    color: white;
    height: 48px;
  }

  &.black {
    background: #1D2C3F;
    color: white;
    font-weight: 700;
  }

  &:disabled, &[disabled], .cta-disabled {
    background: none !important;
    border: solid 1px $gray-99;
    color: $gray-99;
    font-weight: normal;
    cursor: default;
  }
}

.modal-heading,
.modal-subheading {
  font-size: 20px;
  font-weight: 500;
  margin-bottom: 20px;
}

.modal-subheading {
  margin-top: 20px;
}

.modal-form {
  margin-top: 16px;
  min-width: 350px;
  min-height: 200px;

  display: flex;
  flex-direction: column;

  .controls-wrapper {
    margin-top: auto;
    padding-top: 16px;
    display: flex;
    justify-content: flex-end;

    button {
      min-width: 100px;
    }

    & > * {
      margin-left: 8px;

      &:first-child {
        margin-left: revert;
      }
    }
  }

  @media screen and (max-width: 500px) {
    min-width: 210px;
  }
}

@font-face {
  font-family: 'pickwings';
  src: url('/assets/fonts/pickwings.eot');
  src: url('/assets/fonts/pickwings.eot?#iefix') format('embedded-opentype'),
  url('/assets/fonts/pickwings.woff') format('woff'),
  url('/assets/fonts/pickwings.ttf') format('truetype'),
  url('/assets/fonts/pickwings.svg#pickwings') format('svg');
  font-weight: 400;
  font-style: normal;
}

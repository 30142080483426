@import "theme-factory";

$primary-color: (
  usual-color: #b7ce11,
  lighter-color: #e9f0b8,
  darker-color: #9fbc0a,
  is-text-color-light: false,
  is-lighter-color-light: false,
  is-darker-color-light: false,
);
$accent-color: (
  usual-color: #007bff,
  lighter-color: #b3d7ff,
  darker-color: #005eff,
  is-text-color-light: true,
  is-lighter-color-light: false,
  is-darker-color-light: true,
);
$warn-color: (
  usual-color: #ff0000,
  lighter-color: #ffb3b3,
  darker-color: #721c24,
  is-text-color-light: true,
  is-lighter-color-light: false,
  is-darker-color-light: true,
);

// Theme Init
.yellowgreen-theme {
  @include custom-mat-theme($primary-color, $accent-color, $warn-color);
}

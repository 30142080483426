/* You can add global styles to this file, and also import other style files */
@import "https://api.mapbox.com/mapbox-gl-js/v2.1.1/mapbox-gl.css";
// @import "~ngx-toastr/toastr.css";
@import "projects/shared/styles/theme/yellowgreen";
@import "projects/shared/styles/headers";
@import "projects/shared/styles/text-colors";
@import "projects/shared/styles/buttons";
@import "projects/shared/styles/fonts";
@import "projects/shared/styles/dialog-modal";
@import "~@ng-select/ng-select/themes/default.theme.css";

.page-block {
  display: block;
  padding: 16px 15px 15px;

  @media (max-width: 575px) {
    &.container {
      max-width: inherit;
    }
  }
}

.mat-tab-link:hover {
  text-decoration: none;
  color: inherit;
}

.relative {
  position: relative;
}

.modal {
  z-index: 100000;
}

a {
  color: $bondi-blue-ae;
}

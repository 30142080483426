@import "constants";

.green {
  color: $green-11;
}

.orange {
  color: $orange-16;
}

.red {
  color: $pink-5b;
}

.bondi-blue {
  color: $bondi-blue-ae;
}

.dark-blue {
  color: $dark-blue-3f;
}

.primary {
  color: var(--primary-color);
}
.accent {
  color: var(--accent-color);
}
.warn {
  color: var(--warn-color, #e5005b);
}

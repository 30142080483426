h1 {
  text-transform: uppercase;
  font-size: 28px;
  font-weight: 400;
  margin-top: 18px;
  margin-bottom: 7px;
  word-spacing: 2px;
}

h2 {
  text-transform: uppercase;
  font-size: 24px;
  font-weight: 300;
  margin-top: 18px;
  margin-bottom: 5px;
}

h3 {
  text-transform: uppercase;
  font-size: 21px;
  font-weight: 300;
  margin-top: 18px;
  margin-bottom: 4px;
}

h4 {
  text-transform: uppercase;
  font-size: 18px;
  font-weight: 300;
  margin-top: 6px;
  margin-bottom: 0;
}

/**
* for get sets of colors use Material Theme Generator
* https://materialtheme.arcsine.dev
*/

@use "sass:map";
@use '@angular/material' as mat;
@import '@angular/material/theming';

@import 'https://fonts.googleapis.com/css?family=Material+Icons';
@import url('https://fonts.googleapis.com/css?family=Roboto:300,400,500');

@function is-text-color-light($is-light, $light-text, $dark-text) {
  @if ($is-light) {
    @return $light-text
  } @else {
    @return $dark-text
  }
}

@function get-theme-config($color, $light-text, $dark-text) {
  @return (
    main: map.get($color, usual-color),
    lighter: map.get($color, lighter-color),
    darker: map.get($color, darker-color),
    200: map.get($color, usual-color), // For slide toggle,
    contrast : (
      main: is-text-color-light(map.get($color, is-text-color-light), $light-text, $dark-text),
      lighter: is-text-color-light(map.get($color, is-lighter-color-light), $light-text, $dark-text),
      darker: is-text-color-light(map.get($color, is-darker-color-light), $light-text, $dark-text),
    )
  )
}

@mixin custom-mat-theme($primary-color, $accent-color, $warn-color) {
  $fontConfig: (
    display-4: mat.define-typography-level(112px, 112px, 300, 'Roboto', -0.0134em),
    display-3: mat.define-typography-level(56px, 56px, 400, 'Roboto', -0.0089em),
    display-2: mat.define-typography-level(45px, 48px, 400, 'Roboto', 0.0000em),
    display-1: mat.define-typography-level(34px, 40px, 400, 'Roboto', 0.0074em),
    headline: mat.define-typography-level(24px, 32px, 400, 'Roboto', 0.0000em),
    title: mat.define-typography-level(20px, 32px, 500, 'Roboto', 0.0075em),
    subheading-2: mat.define-typography-level(16px, 28px, 400, 'Roboto', 0.0094em),
    subheading-1: mat.define-typography-level(15px, 24px, 500, 'Roboto', 0.0067em),
    body-2: mat.define-typography-level(14px, 24px, 500, 'Roboto', 0.0179em),
    body-1: mat.define-typography-level(14px, 20px, 400, 'Roboto', 0.0179em),
    button: mat.define-typography-level(14px, 14px, 500, 'Roboto', 0.0893em),
    caption: mat.define-typography-level(12px, 20px, 400, 'Roboto', 0.0333em),
    input: mat.define-typography-level(inherit, 1.125, 400, 'Roboto', 1.5px)
  );

  // Foreground Elements

  // Light Theme Text
  $dark-text: #000000;
  $dark-primary-text: rgba($dark-text, 0.87);
  $dark-accent-text: rgba($dark-primary-text, 0.54);
  $dark-disabled-text: rgba($dark-primary-text, 0.38);
  $dark-dividers: rgba($dark-primary-text, 0.12);
  $dark-focused: rgba($dark-primary-text, 0.12);

  $mat-light-theme-foreground: (
    base: black,
    divider: $dark-dividers,
    dividers: $dark-dividers,
    disabled: $dark-disabled-text,
    disabled-button: rgba($dark-text, 0.26),
    disabled-text: $dark-disabled-text,
    elevation: black,
    secondary-text: $dark-accent-text,
    hint-text: $dark-disabled-text,
    accent-text: $dark-accent-text,
    icon: $dark-accent-text,
    icons: $dark-accent-text,
    text: $dark-primary-text,
    slider-min: $dark-primary-text,
    slider-off: rgba($dark-text, 0.26),
    slider-off-active: $dark-disabled-text,
  );

  // Dark Theme text
  $light-text: #ffffff;
  $light-primary-text: $light-text;
  $light-accent-text: rgba($light-primary-text, 0.7);
  $light-disabled-text: rgba($light-primary-text, 0.5);
  $light-dividers: rgba($light-primary-text, 0.12);
  $light-focused: rgba($light-primary-text, 0.12);

  $mat-dark-theme-foreground: (
    base: $light-text,
    divider: $light-dividers,
    dividers: $light-dividers,
    disabled: $light-disabled-text,
    disabled-button: rgba($light-text, 0.3),
    disabled-text: $light-disabled-text,
    elevation: black,
    hint-text: $light-disabled-text,
    secondary-text: $light-accent-text,
    accent-text: $light-accent-text,
    icon: $light-text,
    icons: $light-text,
    text: $light-text,
    slider-min: $light-text,
    slider-off: rgba($light-text, 0.3),
    slider-off-active: rgba($light-text, 0.3),
  );

  // Background config
  // Light bg
  $light-background: #fafafa;
  $light-bg-darker-5: darken($light-background, 5%);
  $light-bg-darker-10: darken($light-background, 10%);
  $light-bg-darker-20: darken($light-background, 20%);
  $light-bg-darker-30: darken($light-background, 30%);
  $light-bg-lighter-5: lighten($light-background, 5%);
  $dark-bg-tooltip: lighten(#2c2c2c, 20%);
  $dark-bg-alpha-4: rgba(#2c2c2c, 0.04);
  $dark-bg-alpha-12: rgba(#2c2c2c, 0.12);

  $mat-light-theme-background: (
    background: $light-background,
    status-bar: $light-bg-darker-20,
    app-bar: $light-bg-darker-5,
    hover: $dark-bg-alpha-4,
    card: $light-bg-lighter-5,
    dialog: $light-bg-lighter-5,
    tooltip: $dark-bg-tooltip,
    disabled-button: $dark-bg-alpha-12,
    raised-button: $light-bg-lighter-5,
    focused-button: $dark-focused,
    selected-button: $light-bg-darker-20,
    selected-disabled-button: $light-bg-darker-30,
    disabled-button-toggle: $light-bg-darker-10,
    unselected-chip: $light-bg-darker-10,
    disabled-list-option: $light-bg-darker-10,
  );

  // Dark bg
  $dark-background: #2c2c2c;
  $dark-bg-lighter-5: lighten($dark-background, 5%);
  $dark-bg-lighter-10: lighten($dark-background, 10%);
  $dark-bg-lighter-20: lighten($dark-background, 20%);
  $dark-bg-lighter-30: lighten($dark-background, 30%);
  $light-bg-alpha-4: rgba(#fafafa, 0.04);
  $light-bg-alpha-12: rgba(#fafafa, 0.12);

  // Background palette for dark themes.
  $mat-dark-theme-background: (
    background: $dark-background,
    status-bar: $dark-bg-lighter-20,
    app-bar: $dark-bg-lighter-5,
    hover: $light-bg-alpha-4,
    card: $dark-bg-lighter-5,
    dialog: $dark-bg-lighter-5,
    tooltip: $dark-bg-lighter-20,
    disabled-button: $light-bg-alpha-12,
    raised-button: $dark-bg-lighter-5,
    focused-button: $light-focused,
    selected-button: $dark-bg-lighter-20,
    selected-disabled-button: $dark-bg-lighter-30,
    disabled-button-toggle: $dark-bg-lighter-10,
    unselected-chip: $dark-bg-lighter-20,
    disabled-list-option: $dark-bg-lighter-10,
  );

  // Compute font config
  // @include mat.core($fontConfig);
  @include mat.all-legacy-component-typographies();
  @include mat.core();

  // Theme Config
  $theme-primary: mat.define-palette(get-theme-config($primary-color, $light-primary-text, $dark-primary-text), main, lighter, darker);
  $theme-accent: mat.define-palette(get-theme-config($accent-color, $light-primary-text, $dark-primary-text), main, lighter, darker);
  $theme-warn: mat.define-palette(get-theme-config($warn-color, $light-primary-text, $dark-primary-text), main, lighter, darker);

  // Create the theme object. A theme consists of configurations for individual
  // theming systems such as "color" or "typography".
  $theme: mat.define-light-theme((
    color: (
      primary: $theme-primary,
      accent: $theme-accent,
      warn: $theme-warn,
    ),
    typography: mat.define-typography-config(),
  ));

  // @include mat.core-theme($theme);
  // @include mat.button-theme($theme);

  // Include theme styles for core and each component used in your app.
  // Alternatively, you can import and @include the theme mixins for each component
  // that you are using.
  // @include mat.all-component-themes($theme);

  // $theme: (
  //   primary: $theme-primary,
  //   accent: $theme-accent,
  //   warn: $theme-warn,
  //   is-dark: false,
  //   foreground: $mat-light-theme-foreground,
  //   background: $mat-light-theme-background,
  // );
  $altTheme: (
    primary: $theme-primary,
    accent: $theme-accent,
    warn: $theme-warn,
    is-dark: true,
    foreground: $mat-dark-theme-foreground,
    background: $mat-dark-theme-background,
  );

  --primary-color: #{map.get($primary-color, usual-color)};
  --primary-lighter-color: #{map.get($primary-color, lighter-color)};
  --primary-lighter-color-50: #{rgba(map.get($primary-color, lighter-color), 0.5)};
  --primary-darker-color: #{map.get($primary-color, darker-color)};
  --text-primary-color: #{is-text-color-light(map.get($primary-color, is-text-color-light), $light-primary-text, $dark-primary-text)};
  --text-primary-lighter-color: #{is-text-color-light(map.get($primary-color, is-lighter-color-light), $light-primary-text, $dark-primary-text)};
  --text-primary-darker-color: #{is-text-color-light(map.get($primary-color, is-darker-color-light), $light-primary-text, $dark-primary-text)};

  --accent-color: #{map.get($accent-color, usual-color)};
  --accent-lighter-color: #{map.get($accent-color, lighter-color)};
  --accent-lighter-color-50: #{rgba(map.get($accent-color, lighter-color), 0.5)};
  --accent-darker-color: #{map.get($accent-color, darker-color)};
  --text-accent-color: #{is-text-color-light(map.get($accent-color, is-text-color-light), $light-primary-text, $dark-primary-text)};
  --text-accent-lighter-color: #{is-text-color-light(map.get($accent-color, is-lighter-color-light), $light-primary-text, $dark-primary-text)};
  --text-accent-darker-color: #{is-text-color-light(map.get($accent-color, is-darker-color-light), $light-primary-text, $dark-primary-text)};

  --warn-color: #{map.get($warn-color, usual-color)};
  --warn-lighter-color: #{map.get($warn-color, lighter-color)};
  --warn-lighter-color-50: #{rgba(map.get($warn-color, lighter-color), 0.5)};
  --warn-darker-color: #{map.get($warn-color, darker-color)};
  --text-warn-color: #{is-text-color-light(map.get($warn-color, is-text-color-light), $light-primary-text, $dark-primary-text)};
  --text-warn-lighter-color: #{is-text-color-light(map.get($warn-color, is-lighter-color-light), $light-primary-text, $dark-primary-text)};
  --text-warn-darker-color: #{is-text-color-light(map.get($warn-color, is-darker-color-light), $light-primary-text, $dark-primary-text)};

  &.light-theme {
    @include mat.all-component-themes($theme);
  }

  &.dark-theme {
    @include mat.all-component-themes($altTheme);
  }

  // Specific component overrides, pieces that are not in line with the general theming

  // Handle buttons appropriately, with respect to line-height
  // mdc-button mdc-button--outlined mat-mdc-outlined-button mat-primary mat-mdc-button-base
  .mat-raised-button, .mat-stroked-button, .mat-flat-button, .mat-mdc-outlined-button, .mat-mdc-unelevated-button {
    padding: 0 1.15em;
    margin: 0 .65em;
    min-width: 3em;
    line-height: 36.4px;

    &:first-child {
      margin-left: 0;
    }

    &:last-child {
      margin-right: 0;
    }
  }

  &.light-theme .mat-focused .mat-form-field-required-marker,
  &.dark-theme .mat-focused .mat-form-field-required-marker {
    color: inherit;
  }

  .mat-form-field-appearance-legacy.mat-form-field-disabled {
    .mat-form-field-underline {
      background-color: revert;
    }

    .mat-form-field-suffix .mat-icon {
      filter: grayscale(1);
    }
  }

  .mat-standard-chip {
    padding: .5em .85em;
    min-height: 2.5em;
  }

  .material-icons {
    font-size: 24px;
    font-family: 'Material Icons', 'Material Icons';

    .mat-badge-content {
      font-family: 'Roboto';
    }
  }

  .mat-mdc-dialog-container {
    .mdc-dialog__surface {
        padding: 24px;
    }
  }
  .mdc-dialog .mdc-dialog__content {
      padding: 0 !important;
  }
  // .mat-mdc-dialog-container, .mdc-dialog .mdc-dialog__content {
  //     @apply text-gray-800 dark:text-gray-100 #{'!important'};
  // }
}
